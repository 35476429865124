import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  Edit,
  FormDataConsumer,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
  regex,
  required,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { useStyles } from "./styles";

const ApiSettings = ({ props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [apiLink, setApiLink] = useState();
  const [data, setData] = useState();
  const [value, setValue] = useState(0);
  const URL_REGEX = /^(?:https?|http):\/\/\w+(.\w+)$/i

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dataProvider
      .getOne("merchant/account", {})
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
    const request = {
      method: "GET",
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    axios(
      `${window._env_.REACT_APP_API_HOST}` +
        "/merchant/api/merchant/account/apm",
      request
    )
      .then((data) => {
        setApiLink(data.data?.apmStoreLink);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    function CustomTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const globalReturnRadioLabels = [
        {
            id: true,
            name: <RadioLabel
                    classes={classes}
                    title={translate('apiForm.global.enhanced_get_request')}
                    description={translate('apiForm.global.we_shall_direct_users_back_text')}
                    sampleLink={"https://myshop.example/return/{merchantReference}?merchantReference={merchantReference}&status={status}"}
                    method={"GET"}
                />,
            className: classes.radioBtn,
            sx: {
                backgroundColor: "green"
            }
        },
        {
            id: false,
            name: <RadioLabel
                    classes={classes}
                    title={translate('apiForm.global.simple_get_request')}
                    description={translate('apiForm.global.we_shall_direct_users_back_second_text')}
                    sampleLink={"https://myshop.example/return/"}
                    method={"GET"}
                />,
            className: classes.radioBtn
        }
    ];

    const instantNotificationRadioLabels = [
        {
            id: false,
            name: <RadioLabel
                    classes={classes}
                    title={translate('apiForm.put_request')}
                    description={translate('apiForm.instant.we_shall_send_a_put_request_text')}
                    sampleLink={'https://myshop.example/instant/{merchantReference} with the body {"status":"[txStatus]"}'}
                    method={"PUT"}
                />
        },
        {
            id: true,
            name: <RadioLabel
                    classes={classes}
                    title={translate('apiForm.get_request')}
                    description={translate('apiForm.instant.we_shall_send_a_get_request_text')}
                    sampleLink={"https://myshop.example/instant/{merchantReference}?tx_id={merchantReference}&status=[txStatus]"}
                    method={"GET"}
                />
        }
    ]

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const globalReturnRadioLabels = [
    {
      id: true,
      name: (
        <RadioLabel
          classes={classes}
          title={translate("apiForm.global.enhanced_get_request")}
          description={translate(
            "apiForm.global.we_shall_direct_users_back_text"
          )}
          sampleLink={
            "https://myshop.example/return/{merchantReference}?merchantReference={merchantReference}&status={status}"
          }
          method={"GET"}
        />
      ),
      className: classes.radioBtn,
      sx: {
        backgroundColor: "green",
      },
    },
    {
      id: false,
      name: (
        <RadioLabel
          classes={classes}
          title={translate("apiForm.global.simple_get_request")}
          description={translate(
            "apiForm.global.we_shall_direct_users_back_second_text"
          )}
          sampleLink={"https://myshop.example/return/"}
          method={"GET"}
        />
      ),
      className: classes.radioBtn,
    },
  ];

  const instantNotificationRadioLabels = [
    {
      id: false,
      name: (
        <RadioLabel
          classes={classes}
          title={translate("apiForm.put_request")}
          description={translate(
            "apiForm.instant.we_shall_send_a_put_request_text"
          )}
          sampleLink={
            'https://myshop.example/instant/{merchantReference} with the body {"status":"[txStatus]"}'
          }
          method={"PUT"}
        />
      ),
    },
    {
      id: true,
      name: (
        <RadioLabel
          classes={classes}
          title={translate("apiForm.get_request")}
          description={translate(
            "apiForm.instant.we_shall_send_a_get_request_text"
          )}
          sampleLink={
            "https://myshop.example/instant/{merchantReference}?tx_id={merchantReference}&status=[txStatus]"
          }
          method={"GET"}
        />
      ),
    },
  ];

  return (
    <Edit
      id={data?.id}
      redirect={false}
      classes={{
        card: classes.paper,
      }}
      resource="merchant/account"
      title={"apiForm.apiSettings"}
      basePath="/api-settings"
      {...props}
    >
      <SimpleForm
        toolbar={<CustomToolbar handleSubmitWithRedirect={"list"} />}
        className={classes.form}
      >
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                <Grid className={classes.titleForm}>
                  {translate("apiForm.apiSettings")}
                </Grid>
                <Grid>
                  <Grid className={classes.text}>
                    {translate("apiForm.text")}
                    <a
                      href={`${window._env_.REACT_API_MANAGER}`}
                      target="_blank"
                    >
                      {translate("apiForm.link")}
                    </a>
                  </Grid>
                </Grid>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    className={classes.tabPanel}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="disabled"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label={translate("apiForm.global_return_url")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={translate("apiForm.instant_notification_url")}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                  <CustomTabPanel value={value} index={0}>
                    <Grid className={classes.wrapper}>
                      <Grid className={classes.text}>
                        <Typography className={classes.firstParagraph}>
                          {translate(
                            "apiForm.global.the_return_url_is_being_called_text"
                          )}
                        </Typography>
                        <Typography>
                          {translate(
                            "apiForm.global.you_can_define_a_global_url_text"
                          )}
                        </Typography>
                      </Grid>

                      <TextInput
                        classes={{
                          root: classes.inputMedium,
                        }}
                        label="apiForm.fieldReturnUrl"
                        source="callbackUrl.returnUrl"
                        placeholder={translate(
                          "apiForm.enter_the_return_url_here"
                        )}
                        validate={[required(translate("apiForm.global.please_provide_a_return_url")), regex(URL_REGEX)]}
                      />
                      <Separator />

                      <FormControl className={classes.formControl}>
                        <FormLabel
                          className={classes.radioTitle}
                          id="demo-radio-buttons-group-label"
                        >
                          {translate(
                            "apiForm.global.please_specify_how_you_would_text"
                          )}
                        </FormLabel>
                        <RadioButtonGroupInput
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={String(
                            data?.callbackUrl?.enhancedReturnURL
                          )}
                          source="callbackUrl.enhancedReturnURL"
                          label=""
                          name="callbackUrl.enhancedReturnURL"
                          className={classes.radioGroup}
                          choices={globalReturnRadioLabels}
                        ></RadioButtonGroupInput>
                      </FormControl>
                    </Grid>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Grid className={classes.wrapper}>
                      <Grid className={classes.text}>
                        <Typography className={classes.firstParagraph}>
                          {translate(
                            "apiForm.instant.provide_a_url_for_immediate"
                          )}
                        </Typography>
                        <Typography>
                          {translate(
                            "apiForm.instant.this_url_will_be_used"
                          )}
                        </Typography>
                      </Grid>

                      <TextInput
                        classes={{
                          root: classes.inputMedium,
                        }}
                        label="apiForm.fieldInstantNoteUrl"
                        source="callbackUrl.notificationUrl"
                        placeholder={translate(
                          "apiForm.enter_your_notification_url_here"
                        )}
                        validate={[required(translate("apiForm.instant.please_provide_a_notification_url")), regex(URL_REGEX)]}
                      />
                      <Separator />

                      <FormControl className={classes.formControl}>
                        <FormLabel
                          className={classes.radioTitle}
                          id="demo-radio-buttons-group-label"
                        >
                          {translate(
                            "apiForm.instant.please_specify_how_you_would_text"
                          )}
                        </FormLabel>
                        <RadioButtonGroupInput
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={String(
                            data?.callbackUrl?.sendNotificationByGet
                          )}
                          source="callbackUrl.sendNotificationByGet"
                          label=""
                          name="callbackUrl.sendNotificationByGet"
                          className={classes.radioGroup}
                          choices={instantNotificationRadioLabels}
                        ></RadioButtonGroupInput>
                      </FormControl>
                    </Grid>
                  </CustomTabPanel>
                </Box>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const RadioLabel = ({
  title = "",
  method = "",
  description = "",
  classes,
  sampleLink = "",
}) => {
  return (
    <Box className={classes.radioContainer}>
      <Typography className={classes.radioLabel}>{title}</Typography>
      <Box className={classes.descriptionWrapper}>
        <Typography className={classes.radioLabelDescription}>
          {description}
        </Typography>
        {sampleLink && (
          <Typography className={classes.sampleLink}>
            Example: {method} {sampleLink}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
const Separator = () => <Box pt="1em" />;
export default ApiSettings;
